import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBoatAlt,
  cilBug,
  cilClipboard,
  cilBuilding, cilCreditCard, cilChartLine, cilGroup, cilInfo, cilCalendarCheck
} from '@coreui/icons'
import {CNavGroup, CNavItem} from '@coreui/react'
import Roles from "./config/roles";

const _nav = [
  {
    component: CNavItem,
    name: 'Ubicaciones',
    to: '/ubicaciones',
    icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
    roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.SAT.role, Roles.ALMACEN.role]
  },
  {
    component: CNavItem,
    name: 'Inventario',
    to: '/inventario',
    icon: <CIcon icon={cilCalendarCheck} customClassName="nav-icon" />,
    roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.SAT.role, Roles.ALMACEN.role]
  },
  {
    component: CNavGroup,
    name: 'Reponer',
    icon: <CIcon icon={cilClipboard} customClassName="nav-icon" />,
    roles: [Roles.ADMIN.role, Roles.REPONEDOR.role],
    items: [
      {
        component: CNavItem,
        name: 'Necesidad inmediata',
        to: '/reponer-inmediato',
        roles: [Roles.ADMIN.role, Roles.REPONEDOR.role]
      },
      {
        component: CNavItem,
        name: 'Reposición general',
        to: '/reponer-general',
        roles: [Roles.ADMIN.role, Roles.REPONEDOR.role]
      }
    ],
  },
  {
    component: CNavGroup,
    name: 'Rmas',
    icon: <CIcon icon={cilBug} customClassName="nav-icon" />,
    roles: [Roles.ADMIN.role, Roles.SAT.role],
    items: [
      {
        component: CNavItem,
        name: 'RMA Tramitado',
        to: '/rma/2',
        roles: [Roles.ADMIN.role, Roles.SAT.role]
      },
      {
        component: CNavItem,
        name: 'RMA Pendiente',
        to: '/rma/4',
        roles: [Roles.ADMIN.role, Roles.SAT.role]
      }
    ],
  },
  // {
  //   component: CNavItem,
  //   name: 'Rutas',
  //   to: '/rutas',
  //   icon: <CIcon icon={cilBoatAlt} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Compras',
    to: '/compras',
    icon: <CIcon icon={cilCreditCard} customClassName="nav-icon" />,
    roles: [Roles.ADMIN.role, Roles.REPONEDOR.role]
  },
  {
    component: CNavGroup,
    name: 'Ventas',
    icon: <CIcon icon={cilChartLine} customClassName="nav-icon" />,
    roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role],
    items: [
      {
        component: CNavItem,
        name: 'Pasar a preparación',
        to: '/estados',
        roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role]
      },
      {
        component: CNavItem,
        name: 'Estados pedidos',
        to: '/listado-estados',
        roles: [Roles.ADMIN.role]
      },
      {
        component: CNavItem,
        name: 'Pedidos',
        to: '/ventas',
        roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role]
      },
      {
        component: CNavItem,
        name: 'Pedidos Nave 2',
        to: '/pedidosn2',
        roles: [Roles.ADMIN.role, Roles.ALMACEN.role, Roles.REPONEDOR.role]
      }
    ],
  },
  {
    component: CNavItem,
    name: 'Info pedidos',
    to: '/info',
    icon: <CIcon icon={cilInfo} customClassName="nav-icon" />,
    roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role, Roles.SAT.role]
  },
  {
    component: CNavGroup,
    name: 'Gestión rutas',
    icon: <CIcon icon={cilBoatAlt} customClassName="nav-icon" />,
    roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role, Roles.SAT.role],
    items: [
      {
        component: CNavItem,
        name: 'Alta ruta',
        to: '/rutas2',
        roles: [Roles.ADMIN.role, Roles.SAT.role]
      },
      {
        component: CNavItem,
        name: 'Sacar etiqueta',
        to: '/panel',
        roles: [Roles.ADMIN.role, Roles.REPONEDOR.role, Roles.ALMACEN.role, Roles.SAT.role]
      },
      // {
      //   component: CNavItem,
      //   name: 'Rutas',
      //   to: '/repartos',
      // },
    ],
  },
  {
    component: CNavItem,
    name: 'Empleados',
    to: '/empleados',
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
    roles: [Roles.ADMIN.role]
  },
]

export default _nav
