import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {CModalTitle} from "@coreui/react";
import parse from "html-react-parser";
import CIcon from "@coreui/icons-react";
import {cilWarning} from "@coreui/icons";


class ConfirmationM extends React.Component {

    constructor(props) {
        super(props);
        this.confirmButtonRef = React.createRef();
        this.cancelButtonRef = React.createRef();
        this.iconComponents = {
            cilWarning: cilWarning,
        };
    }

    handleClose() {
        this.props.handleClose();
    }

    handleConfirm(){
        this.props.handleConfirm();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show) {
            let focusElement;
            if (!this.props.disableConfirm) {
                focusElement = this.confirmButtonRef;
            } else {
                focusElement = this.cancelButtonRef;
            }

            setTimeout(() => {
                focusElement.current.focus();
            }, 100);
        }
    }

    render() {
        const {show, title, text, iconName, disableConfirm} = this.props;
        const iconComponent = this.iconComponents[iconName];

        return (
            <Modal id='modal-confirmation' show={show} onHide={() => this.handleClose()} centered>
                <Modal.Header closeButton>
                    <CModalTitle>
                        {iconName !== undefined ? <CIcon icon={iconComponent} size='sm' /> : ''}
                        {parse(title)}</CModalTitle>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {parse(text)}
                </Modal.Body>
                <Modal.Footer>
                    {disableConfirm ? '' :
                        <Button variant="primary" ref={this.confirmButtonRef} onClick={() => this.handleConfirm()}>
                            Confirmar
                        </Button>
                    }
                    <Button variant="secondary" ref={this.cancelButtonRef} onClick={() => this.handleClose()}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ConfirmationM;