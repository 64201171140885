import React from "react";

class PrintProducts extends React.Component {

    render() {
        return <div>
            {Object.keys(this.props.print).map((key, index) => {
                if (this.props.print[index].checked === true) {
                    return <PrintProduct key={index} index={index} print={this.props.print} />
                }
                return null;
            })}
        </div>;
    }
}

class PrintProduct extends React.Component {

    render() {

        const {print, index} = this.props;

        return (
            <div className="hidden">
                <div className="row">
                    <div className="col-12 d-inline-flex align-items-center">
                        <label className="form-label mb-0 me-2">Nombre:</label>
                        <div className="form-text mt-0">{print[index].name}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-inline-flex align-items-center">
                        <label className="form-label mb-0 me-2">Referencia:</label>
                        <div className="form-text mt-0">{print[index].ref}</div>
                    </div>
                </div>
                <div
                    className={print[index].ean[0] === undefined || print[index].ean[0].BARRAS === '' ? 'd-none' : 'row'}>
                    <div className="col-12 d-inline-flex align-items-center">
                        <label className="form-label mb-0 me-2">Código barras:</label>
                        <div
                            className="form-text mt-0"> {print[index].ean[0] !== undefined ? print[index].ean[0].BARRAS : ''}</div>
                    </div>
                </div>
                {
                    print[index].fields?.length > 0 && print[index].fields?.map((item, fieldIndex) => {
                        return (
                            <div
                                key={fieldIndex}
                                className={(item.CAMPO === '117' || item.CAMPO === '118') && (item.VALOR === '' || item.VALOR === '0') ? 'd-none' : 'row'}>
                                <div className="col-12 d-inline-flex align-items-center">
                                    <label className="form-label mb-0 me-2">
                                        {item.CAMPO === '###' ? 'Publicado web:' : ''}
                                        {item.CAMPO === '117' ? 'Caja individual:' : ''}
                                        {item.CAMPO === '118' ? 'Caja grande:' : ''}
                                    </label>
                                    <div className="form-text mt-0">
                                        {item.CAMPO === '###' ? item.VALOR === '.T.' ? 'No' : 'Si' : ''}
                                        {(item.CAMPO === '117' || item.CAMPO === '118') ? item.VALOR : ''}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    print[index].stock?.length > 0 && print[index].stock?.map((item, stockIndex) => {
                        return (
                            <div className="row" key={stockIndex}>
                                <div className="col-12 d-inline-flex align-items-center">
                                    <label className="form-label mb-0 me-2">Stock
                                        nave {item.ALMACEN === '03' ? '1' : '3'}:</label>
                                    <div className="form-text mt-0">{item.FINAL}</div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="row">
                    <div className="col-12 d-inline-flex align-items-center">
                        <label className="form-label mb-0 me-2">Ubicación:</label>
                        <div className="form-text mt-0">{print[index].location}</div>
                    </div>
                </div>
                <hr/>
            </div>
        );
    }
}

export default PrintProducts;
