import React from "react";
import DeliveryService from "../../services/delivery.service";

import successSound from '../../assets/audio/success.mp3';
import errorSound from '../../assets/audio/error.mp3';
import warningSound from '../../assets/audio/warning.mp3';
import {CButton, CForm, CFormInput} from "@coreui/react";
import {StateContext} from "../context/StateProvider";
import Toasts from "../notificacion/Toasts";
import Utils from "../../utils/Utils";
import {Tooltip} from "@mui/material";

class ListDeliveries extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {qr: '', deliveries: []};
        this.qrRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            deliveries: this.props.deliveries
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.deliveries !== this.props.deliveries) {
            this.setState({deliveries: this.props.deliveries});
        }
    }

    playAudio(sound) {
        const audio = new Audio(sound);
        audio.play();
    }

    handleChange(event) {
        this.setState({qr: event.target.value.replace(/\s/g, "")});
    }

    async handleSubmit(event) {

        if (event) {
            event.preventDefault();
        }

        if (this.state.qr !== "") {

            const {state, updateState} = this.context;

            const qr = this.state.qr.split('-');
            const index = this.state.deliveries.findIndex((objeto) => objeto.serie === qr[0] && objeto.document === qr[1]);

            if (index !== -1 && this.state.deliveries[index].remaining > 0 && this.state.deliveries[index].scanned.findIndex((object) => object.package === this.state.qr) === -1 && parseInt(qr[2]) <= parseInt(this.state.deliveries[index].packages)) {

                const registrado = new Date();

                await DeliveryService.registerBulto(
                    this.state.deliveries[index].delivery,
                    this.state.deliveries[index].serie + '-' + this.state.deliveries[index].document,
                    this.state.qr,
                    state.year,
                    registrado
                ).then(
                    (data) => {
                        this.state.deliveries[index].scanned.push({package: this.state.qr, register: registrado});
                        this.state.deliveries[index].remaining = this.state.deliveries[index].remaining - 1;
                        if (this.state.deliveries[index].remaining === 0) {
                            this.state.deliveries[index].state = 1;
                        }
                        this.state.deliveries[index].registerDate = registrado;
                        this.setState({deliveries: this.state.deliveries});
                        const toasts = Toasts.generateToast( 'Bulto añadido', 'success');
                        updateState({toasts: [...state.toasts, toasts]})
                        this.playAudio(successSound);
                    },
                    (error) => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        const toasts = Toasts.generateToast( resMessage, 'error');
                        updateState({toasts: [...state.toasts, toasts]})
                        this.playAudio(errorSound);
                    });

            } else if (index === -1 || parseInt(qr[2]) > parseInt(this.state.deliveries[index].packages)) {
                const toasts = Toasts.generateToast( 'No existe bulto en este reparto', 'error');
                updateState({toasts: [...state.toasts, toasts]})
                this.playAudio(errorSound);

            } else if (this.state.deliveries[index].scanned.findIndex((object) => object.package === this.state.qr) !== -1) {
                const toasts = Toasts.generateToast( 'Ya se ha añadido el bulto', 'warning');
                updateState({toasts: [...state.toasts, toasts]})
                this.playAudio(warningSound);
            }

            this.setState({qr: ''});
        }

        this.qrRef.current.focus();
    }

    render() {

        return (
            <div id='list-deliveries'>
                {this.state.deliveries.length > 0 ?
                    <div>
                        <CForm onSubmit={(e)=>this.handleSubmit(e)} className="mb-3">
                            <div className="mb-3">
                                <label htmlFor="deliveries-scan" className="form-label">Escanear QR</label>
                                <CFormInput ref={this.qrRef} className="form-control" type="text" id="deliveries-scan"
                                            name="deliveries-scan"
                                            value={this.state.qr} onChange={(e)=>this.handleChange(e)}/>
                            </div>
                            <CButton type="submit" color="primary" className="px-4 col-12">
                                Añadir
                            </CButton>
                        </CForm>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Documento</th>
                                    <th>Bultos</th>
                                    <th>Cliente</th>
                                    <th>Agencia</th>
                                    <th>Bultos restantes</th>
                                    <th>Bultos escaneados</th>
                                    <th>Ult. Registrado</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.deliveries.map(function (d, idx) {
                                    return (
                                        <Delivery key={idx} valor={d}/>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : ''}
            </div>
        );
    }
}

const Delivery = (props) => {

    return (
        <tr className={props.valor.state ? 'alert alert-success' : 'alert alert-danger'}>
            <td>{props.valor.serie}-{props.valor.document}</td>
            <td>{props.valor.packages}</td>
            <td>{props.valor.customer}</td>
            <td>{props.valor.agency}</td>
            <td>{props.valor.remaining}</td>
            <td>
                {props.valor.scanned.map(function (d, idx) {
                    return (
                        <Tooltip className='package-tooltip' key={idx} title={d.register !== null ? Utils.formatDate(d.register) : ''}>
                            <span>{d.package}</span>
                        </Tooltip>
                    )
                })}
            </td>
            <td>{props.valor.registerDate !== null ? Utils.formatDate(props.valor.registerDate) : ''}</td>
        </tr>
    );
};

export default ListDeliveries;