import React from "react";
import dateFormat from "dateformat";


class HistoryProduct extends React.Component {

    render() {
        return (
            <>
                {this.props.history !== undefined && this.props.history.length > 0 ?
                    <div className="mt-3 histoty">
                        <div className="history-table">
                            <h4>Histórico de cambios</h4>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Usuario</th>
                                    <th scope="col">Ubicación</th>
                                    <th scope="col">Fecha</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.props.history.map(function (d, index) {
                                    return (<tr>
                                        <td>{d.username}</td>
                                        <td>{d.ubicacion_new}</td>
                                        <td>{dateFormat(d.updated_date, "dd/mm/yy HH:MM")}</td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                : ''}
            </>

        );
    }
}

export default HistoryProduct;