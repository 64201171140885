import AuthService from "./auth.service";
import axios from "axios";
import HostService from "./host.service";

class ProductService {

    /**
     * Obtener productos
     * @param value
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getProducts(value) {
        return axios.get(HostService.getHost() + 'productos?search=' + value, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    /**
     * Obtener historico de producto
     * @param codigo
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getProductHistory(codigo) {
        return axios.get(HostService.getHost() + 'productos/' + codigo + '/history', AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    /**
     * Obtener productos para reponer stock
     * @param value
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getStockOrderProducts(value, orders) {
        return axios.get(HostService.getHost() + 'stock/warehouse/order?threshold=' + value + '&orders=' + orders, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err.message);
                throw err;
            });
    }

    /**
     * Obtener productos para reponer stock
     * @param value
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getStockGeneralProducts(from, to) {
        return axios.get(HostService.getHost() + 'stock/warehouse?thresholdFrom=' + from + '&thresholdTo=' + to, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.log(err.message);
                throw err;
            });
    }

    /**
     * Actualizar producto
     * @param refProduct
     * @param data
     * @param recovery
     * @returns {Promise<AxiosResponse<any>>}
     */
    static updateProduct(refProduct, data, recovery) {
        let recoveryQuery = '';
        if (recovery) {
            recoveryQuery = '&recuperar=true'
        }
        return axios.put(HostService.getHost() + 'productos?referencia='+refProduct + recoveryQuery, data, AuthService.setToken())
            .then(response => {
                return response.data
            })
            .catch((err) => {
                console.log(err.message);
                throw err;
            });
    }

    /**
     * Comprobar si existe un ean
     * @param ean
     * @returns {Promise<AxiosResponse<any>>}
     */
    static checkEanProduct(ean) {
        return axios.get(HostService.getHost() + 'productos/ean?codigo='+ean, AuthService.setToken())
            .then(response => {
                return response.data
            })
            .catch((err) => {
                throw err;
            });
    }

    /**
     * Obtener proveedores de productos con rotura
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getProviderByWarehouseRma(warehouse) {
        return axios.get(HostService.getHost() + 'rma?almacen='+warehouse, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    /**
     * Obtener productos de un proveedor
     * @param codigo
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getProductProviderByWarehouseRma(warehouse, codigo) {
        return axios.get(HostService.getHost() + 'rma/' + codigo + '?almacen='+warehouse, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    /**
     * Obtener imagen producto
     * @param nameImage
     * @returns {string}
     */
    static generateImageUrl(nameImage) {
        let user = AuthService.getCurrentUser();
        return HostService.getHost() + 'productos/imagenes?nombre='+nameImage+'&token='+user.token;
    }

    /**
     * Obtener inventario producto
     * @param codigo
     * @returns {Promise<AxiosResponse<any>>}
     */
    static getProductInventory(threshold) {
        return axios.get(HostService.getHost() + 'productos/inventario/' + threshold, AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                throw err;
            });
    }

    /**
     * Registrar inventario producto
     * @param codigo
     * @returns {Promise<AxiosResponse<any>>}
     */
    static registerProductInventory(refProduct, warehouse, stock) {
        return axios.put(HostService.getHost() + 'productos/inventario/' + refProduct,
            {
                "referencia": refProduct,
                "almacen": warehouse,
                "stock": stock,
                "fecha": new Date()
            },
            AuthService.setToken())
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                throw err;
            });
    }
}

export default ProductService;
