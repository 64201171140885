import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {CModalTitle} from "@coreui/react";


class Confirmation extends React.Component {

    constructor(props) {
        super(props);
    }

    handleModal() {
        this.props.handleModal();
    }

    handleConfirmation(){
        this.props.handleConfirmation();
    }

    render() {
        const {show} = this.props;

        return (
            <Modal show={show} onHide={() => this.handleModal()} centered>
                <Modal.Header closeButton>
                    <CModalTitle>Atención</CModalTitle>
                </Modal.Header>
                <Modal.Body className="text-center">
                    ¿Esta seguro que quiere continuar con esta acción?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => this.handleConfirmation()}>
                        Confirmar
                    </Button>
                    <Button variant="secondary" onClick={() => this.handleModal(show)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Confirmation;