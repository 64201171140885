class Sticker {

    constructor(id, serie, order, customer, packages, agency, nameAgency, name,
                address, cp, county, state, delivery, nameDelivery, phone, employee,
                nameEmployee, check, created, id_customer, way_pay, expedicionAsigna,
                dateDelivery, counterDelivery, dropshipping, print) {
        this.id = id;
        this.serie = (serie) ? serie.toUpperCase() : serie;
        this.order = order;
        this.customer = customer;
        this.packages = packages;
        this.weight = undefined;
        this.delivery = delivery;
        this.nameDelivery = nameDelivery;
        this.agency = agency;
        this.nameAgency = nameAgency;
        this.name = name;
        this.address = address;
        this.cp = cp;
        this.county = county;
        this.state = state;
        this.phone = phone;
        this.listCustomerAdress = [];
        this.boxes = [];
        this.created = created;
        this.isNew = false;
        this.scanned = false;
        this.dropshipping = (dropshipping ? dropshipping : false);
        this.employee = employee;
        this.nameEmployee = nameEmployee;
        this.check = (check ? check : false);
        this.way_pay = way_pay;
        this.id_customer = id_customer;
        this.expedicionAsigna = expedicionAsigna;
        this.dateDelivery = dateDelivery;
        this.counterDelivery = counterDelivery;
        this.overrideRoute = false;
        this.print = (print ? print : false);
        this.tradename = null;
        this.phonesDefault = [];
        this.comment = null;
    }

    getOrder(){
        return this.serie + '-' + this.order;
    }
}

export default Sticker;