import React from "react";
import ReactToPrint from "react-to-print";
import PrintProducts from "../product/PrintProducts";
import Product from "../product/Product";
import {StateContext} from "../context/StateProvider";

class ListProducts extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
    }

    /**
     * Seleccionar todos los productos
     */
    selectAllProduct() {

        const {state, updateState} = this.context;

        state.products.map((d, index) => {
            state.products[index].checked = true;


        });

        updateState({products: state.products})
    }

    setComponentRef = (ref) => {
        this.componentRef = ref;
    };

    reactToPrintContent = () => {
        return this.componentRef;
    };

    render() {

        const {updateProducts, selectProducts, print = true, inventory = false} = this.props;
        const {state} = this.context;

        return (
            <div id="list-product">
                {state.products.length > 0 && (selectProducts === "1"  )?
                    <div className="panelList sticky-top">
                        {print ?
                            <>
                                <ReactToPrint
                                    trigger={() => {
                                        return <button className="btn btn-secondary col-12">Imprimir</button>;
                                    }}
                                    content={this.reactToPrintContent}
                                />
                                <PrintProducts print={state.products} ref={this.setComponentRef}/>
                            </>
                            : ''}
                        <button className="btn btn-secondary mt-3 col-12" onClick={() => this.selectAllProduct()}>Seleccionar todos</button>
                    </div>
                    : ''}
                <ul>
                    {state.products.map((d, index) => {
                        return (<Product key={index} index={index} update={updateProducts} select={selectProducts} inventory={inventory}/>)
                    })}
                </ul>
            </div>
        );
    }
}

export default ListProducts;