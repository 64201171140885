import React from "react";
import {StateContext} from "../context/StateProvider";
import {CToast, CToastBody} from "@coreui/react";
import parse from "html-react-parser";

class Toast  extends React.Component {

    static contextType = StateContext;

    removeToast = (id) => {
        const {state, updateState} = this.context;
        updateState({toasts: state.toasts.filter((item, i) => i !== id)});
    };

    render() {

        const {toast} = this.props;

        return (
            <CToast onClose={() => this.removeToast(toast.id)} visible={true} delay={5000}>
                <CToastBody className={`toast-${toast.type}`}> {parse(toast.message)}</CToastBody>
            </CToast>
        );
    }
}

export default Toast;