import React from "react";
import CIcon from "@coreui/icons-react";
import {cilPlus} from "@coreui/icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {StateContext} from "../context/StateProvider";
import ProductService from "../../services/product.service";
import Toasts from "../notificacion/Toasts";

class ModalEan extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {show: false, ean: '', units: 0};
    }

    handleModal() {
        this.setState({show: !this.state.show});
        if (!this.state.show) {
            this.setState({ean:''});
        }
    }

    handleAddEan(index) {

        if (this.state.ean !== '') {

            const {state, updateState} = this.context;
            const {updated} = this.props;

            ProductService.checkEanProduct(this.state.ean).then(result => {
                const ean = this.generateEan(state.products[index].ref, this.state.ean, this.state.units);

                if (state.products[index].updated.barras) {
                        state.products[index].updated.barras.push(ean)
                    } else {
                        state.products[index].updated.barras = [ean];
                    }

                    state.products[index].ean.push(ean);

                    updateState({products: state.products})

                    if (updated) {
                        ProductService.updateProduct(state.products[index].ref, state.products[index].updated, false).then(
                            result => {
                                const toasts = Toasts.generateToast(result.message, 'success');
                                state.products[index].updated = {};
                                updateState({products: state.products});
                                updateState({toasts: [...state.toasts, toasts]})
                                this.setState({ean: '', units: 0});
                                this.handleModal();
                            }
                        ).catch((error) => {
                            const toasts = Toasts.generateToast(error.response?.data?.message, 'error');
                            updateState({toasts: [...state.toasts, toasts]})
                            updateState({products: state.products});
                        });
                    } else {
                        this.setState({ean: '', units: 0});
                        this.handleModal();
                    }
                }
            ).catch((error) => {
                const toasts = Toasts.generateToast(error.response?.data?.message, 'error');
                updateState({toasts: [...state.toasts, toasts]})
            });


        }
    }

    generateEan(ref, ean, units) {
        return {
            ARTICULO: ref,
            BARRAS: ean,
            UNIDADES: parseInt(units)
        };
    }

    handleChange(event, type) {
        if (type === 'ean') {
            this.setState({ean: event.target.value})
        } else {
            this.setState({units: event.target.value})
        }
    }

    render() {
        const {show, ean, units} = this.state;
        const {index} = this.props;
        const {state} = this.context;
        const product = state.products[index];

        return (
            <div>
                <CIcon icon={cilPlus} onClick={() => this.handleModal()}
                       customClassName="custom-icon"/>
                <Modal show={show} onHide={() => this.handleModal()} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Añadir código de barras a {product?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input className="form-control mt-0" type='text' value={ean}
                               onChange={(e) => this.handleChange(e, 'ean')} placeholder='Código barras'/>
                        <input className="form-control mt-2" type='text' value={units}
                               onChange={(e) => this.handleChange(e, 'units')} placeholder='Unidades'/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.handleAddEan(index)}>
                            Añadir
                        </Button>
                        <Button variant="secondary" onClick={() => this.handleModal()}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ModalEan;