import React from "react";
import ModalEan from "../modals/EanProduct";
import {CBadge, CButton} from "@coreui/react";
import Confirmation from "../modals/Confirmation";
import {StateContext} from "../context/StateProvider";

class OrderProduct extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    handleForceCheck() {
        this.setState({show: !this.state.show});
    }

    async handleModalConfirmation() {
        const {state, updateState} = this.context;
        const props = this.props;
        const unitsRegister = props.row.register + props.row.remaining;

        let refArticulo = props.row.ref;

        if (props.row.duplicate) {
            refArticulo += '-' + props.row.line;
        }

        await this.props.updateOrderAction(props.registerOrderAction, state, props.row.id, refArticulo, unitsRegister, new Date(), props.row.remaining, updateState, true, state.year);
        this.handleForceCheck();
    }

    render() {

        const {state} = this.context;
        const props = this.props;

        return (
            <div className={`row table-row ${!props.read ? (props.row.state ? 'alert alert-success' : 'alert alert-danger') : ''}`}>
                <div className={`col d-flex flex-column align-items-start justify-content-center ${!props.read ? (props.row.serie ? 'bg-danger text-white' : '') : ''}`}>
                    <div>{props.row.ref}</div>
                    {props.row.manual ? <div><CBadge color="info">Forzado</CBadge></div> : ''}
                    {!props.row.state && !props.read && !state.order.complete  ?
                        <div className='mt-1'>
                            <Confirmation show={this.state.show}
                                          handleConfirmation={() => this.handleModalConfirmation()}
                                          handleModal={() => this.handleForceCheck()}/>
                            <CButton color="warning" className="p-1" onClick={() => {
                                this.handleForceCheck()
                            }}>
                                Forzar punteo
                            </CButton>
                        </div>
                        : ''}
                </div>
                <div className='col d-flex align-items-center'>
                    {props.row.ean.map(item => item.BARRAS + '-' + item.UNIDADES).join(', ')} {props.row.units > 0 && !props.row.state && !props.read && !state.order.complete ?
                        <ModalEan updated={true}
                                  index={props.row.id}/> : ''}
                </div>
                <div className="col d-flex align-items-center">{props.row.name}</div>
                <div className='col d-flex align-items-center'>{props.row.units} {`${!props.read ? '/ ' + props.row.remaining : ''}`} </div>
                <div className='col d-flex align-items-center'>{props.row.location}</div>
                {!props.read ? <div className='col d-flex align-items-center'>{props.row.registerDate}</div> : ''}
            </div>
        );
    }
}


export default OrderProduct;