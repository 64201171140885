import React, {useContext, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {CFormTextarea, CModalTitle} from "@coreui/react";


const Comment = ({show, handleClose, handleConfirm, sticker}) => {

    const [error, setError] = useState('');
    const [comment, setComment] = useState('');

    useEffect(() => {
        setComment(sticker?.comment === null ? '' : sticker?.comment);
    }, [sticker]);

    const handleTextChange = (e) => {
        const value = e.target.value;
        if (value.length > 70) {
            setError('El comentario no puede exceder los 70 caracteres.');
        } else {
            setComment(value);
            setError('');
        }
    };

    const handleConfirmation = () => {
        if (comment.length > 70) {
            setError('El comentario excede los 70 caracteres.');
        } else {
            sticker.comment = comment;
            handleConfirm(sticker);
        }
    };

    return (
        <Modal show={show} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <CModalTitle>Comentario etiqueta</CModalTitle>
            </Modal.Header>
            <Modal.Body>
                <CFormTextarea
                    rows={3}
                    onChange={handleTextChange}
                    text="Máximo 70 caracteres"
                    value={comment}
                    maxLength={70}
                ></CFormTextarea>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => handleConfirmation()}>
                    Guardar
                </Button>
                <Button variant="secondary" onClick={() => handleClose()}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

export default Comment;