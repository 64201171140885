import React, {Component} from "react";
import {StateContext} from "../context/StateProvider";
import Toast from "./Toast";

class Toasts extends Component {

    static contextType = StateContext;

    /**
     * Generar toast
     * @param message
     * @param type
     * @returns {{id: number, message, type}}
     */
    static generateToast(message, type) {
        const toast = {
            id: new Date().getTime(),
            message: message,
            type: type
        };

        return toast;
    }

    render() {
        const {state} = this.context;

        return (
            <>
                <div className="toast-container">
                    {state.toasts.map((toast, index) => (
                        <Toast key={index} toast={toast}/>
                    ))}
                </div>
            </>
        );
    }
}


export default Toasts;