
import Login from "./views/Login";
import React, {Suspense} from "react";
import {HashRouter, Route, Routes} from "react-router-dom";
import PrivateRoute from "./components/PrivateComponent";
import DefaultLayout from "./components/layout/DefaultLayout";
import VoiceUtils from "./utils/VoiceUtils";
import {PermissionProvider} from "react-permission-role";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)


function App() {

    // Cargar voces del navegador
    VoiceUtils.loadVoices();

    return (
        <PermissionProvider>
            <HashRouter>
                <Suspense fallback={loading}>
                    <Routes>
                        <Route exact path="/login" name="Login" element={<Login />}/>
                        <Route path="*" name="Almacen" element={<PrivateRoute component={<DefaultLayout/>}/>}/>
                    </Routes>
                </Suspense>
            </HashRouter>
        </PermissionProvider>
    );
}

export default App;
