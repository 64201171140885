import React, {useState, useContext, useEffect} from 'react';
import {CBadge, CCard, CCardBody, CCol} from "@coreui/react";
import SalesService from "../services/sales.service";
import {StateContext} from "../components/context/StateProvider";
import {DataGrid, esES, GridActionsCellItem} from "@mui/x-data-grid";
import OrderStates from "../utils/OrderStates";
import OrderService from "../services/order.service";
import Toasts from "../components/notificacion/Toasts";
import Utils from "../utils/Utils";
import successSound from "../assets/audio/success.mp3";
import errorSound from "../assets/audio/error.mp3";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EngineeringIcon from '@mui/icons-material/Engineering';
import moment from "moment";
import {Box} from "@mui/material";
import {RECOJO_EN_ALMACEN} from "../config/constants";

const StateOrders = () => {

    const {state, updateState} = useContext(StateContext);
    const [orders, setOrders] = useState([]);
    const [ordersModels, setOrdersModels] = useState({});
    const [loading, setLoading] = useState(true);
    const apiRef = React.createRef();
    apiRef.current = {};

    useEffect(() => {
        const fetchSales = () => {
            SalesService.getStatesSales(state.year).then((data) => {
                setOrders(data.map((row, index) => {
                    const etiqueta = row?.EtiquetasModels?.[0];
                    return {
                        ...row,
                        id: index + 1,
                        ruta: etiqueta ? etiqueta.ruta : undefined,
                        cliente: etiqueta ? etiqueta.cliente : undefined
                    };
                }));
                setLoading(false);
            });
        }
        fetchSales();

        const interval = setInterval(fetchSales, 30000);

        return () => clearInterval(interval);

    }, [state.year]);

    const handleChangeState = async (id, orderState) => {

        let row = apiRef.current.getRow(id);
        const order = row.serie + '-' + row.pedido;

        let toasts;
        try {
            await OrderService.assignToOrder(order, null, null, 'ventas', orderState, null, undefined, state.year, null);
            toasts = Toasts.generateToast(`${order} ha sido cambiado al estado: <strong>${OrderStates.getState(orderState).toLowerCase()}</strong>`, 'success');
            Utils.playAudio(successSound);
            row.estado = orderState;
            apiRef.current.updateRows([
                {
                    id: row.id,
                    ...row,
                },
            ]);
        } catch (error) {
            toasts = Toasts.generateToast(error.response.data.message, 'error');
            Utils.playAudio(errorSound);
        }
        updateState({loading: false});
        updateState({toasts: [...state.toasts, toasts]});
    }

    const generateColumns = () => {
        return [
            {
                field: 'seriePedido',
                headerName: 'Albarán',
                renderCell: (params) => <a
                    href={'#/listado-estados/info/?albaran=' + params.row.serie + '-' + params.row.pedido}
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                >
                    {params.row.serie}-{params.row.pedido}
                </a>,
                flex: 0.8,
            },
            {
                field: 'preparado',
                headerName: 'Preparando',
                flex: 1,
            },
            {
                field: 'revisado',
                headerName: 'Revisando',
                flex: 1,
            },
            {
                field: 'camara',
                headerName: 'Camara',
                flex: 0.8,
            },
            {
                field: 'ruta',
                headerName: 'Ruta',
                flex: 1.2,
                filterable: false,
            },
            {
                field: 'cliente',
                headerName: 'Cliente',
                flex: 2.3,
                filterable: false,
            },
            {
                field: 'fecha',
                headerName: 'Fecha albarán',
                flex: 1,
                filterable: false,
                valueFormatter: params =>
                    moment(params?.value).format("DD/MM/YYYY"),
            },
            {
                field: 'estado',
                headerName: 'Estado',
                flex: 1,
                filterable: false,
                renderCell: (params) => <CBadge
                    color={params.value === '1' ? 'info' : (params.value === '2' ? 'danger' : (params.value === '4' ? 'warning' : 'success'))}>{OrderStates.getState(params.value)}</CBadge>,
            },
            {
                field: 'almacen',
                headerName: 'Nave',
                flex: 0.6,
                filterable: false,
                renderCell: (params) => <>Nave {params.value === '03' ? '1' : params.value === '05' ? '4' : '3'}</>,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: '',
                width: 150,
                cellClassName: 'actions',
                getActions: ({id}) => {

                    return [
                        <GridActionsCellItem
                            icon={<ClearIcon/>}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => handleChangeState(id, '1')}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<EngineeringIcon/>}
                            label="Delete"
                            onClick={() => handleChangeState(id, '2')}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<CheckIcon/>}
                            label="Check"
                            onClick={() => handleChangeState(id, '3')}
                            color="inherit"
                        />,
                    ];
                },
            },
        ];
    };

    return (
        <CCol id="sales" md={12}>
            <CCard className="p-3">
                <CCardBody>
                    <Box
                        sx={{
                            '& .include': {
                                backgroundColor: '#ff943975',
                                color: '#1a3e72',
                            },
                        }}
                    >
                        <DataGrid
                            rows={orders}
                            columns={generateColumns()}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            autoHeight={true}
                            loading={loading}
                            apiRef={apiRef}
                            rowModesModel={ordersModels}
                            getRowClassName={(params) => {
                                return params.row.transporte.includes(RECOJO_EN_ALMACEN) ? "include" : "";
                            }}
                        />
                    </Box>
                </CCardBody>
            </CCard>
        </CCol>
    );
};

export default StateOrders;