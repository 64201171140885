import React from 'react';
import Order from "../../models/Order";
import EmployeesService from "../../services/employees.service";

export const StateContext = React.createContext();

export class StateProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarShow: true,
            sidebarUnfoldable: false,
            messages: [], // Quitar, pero comprobar si nadie lo usa
            loading: false,
            products: [],
            toasts: [],
            order: new Order(),
            filters: [],
            year: (new Date()).getFullYear(),
            employees: []
        };
    }

    async componentDidMount() {
        this.updateState({employees: await EmployeesService.getEmployees()});
    }

    updateState = (newState) => {
        this.setState(newState);
    };

    render() {
        return (
            <StateContext.Provider
                value={{
                    state: this.state,
                    updateState: this.updateState,
                }}
            >
                {this.props.children}
            </StateContext.Provider>
        );
    }
}
