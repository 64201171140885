import React, {Component} from 'react'
import { CFooter } from '@coreui/react'
import {appVersion} from "../../version";

class Footer extends Component {
    render() {
        return (
            <CFooter className='mt-4'>
                <div className='d-flex justify-content-between col-12'>
                    <span className="ms-1">&copy; 2023 Mandatelo</span>
                    <span>Vesión:{appVersion}</span>
                </div>
            </CFooter>
        )
    }
}

export default React.memo(Footer)
