import {format, parseISO} from "date-fns";

class Product {

    constructor(order, ref, ean, name, units, location, serie, remaining, state, register, images, fields, stock, replace, duplicate, line, registerDate, manual, id) {
        this.id = id;
        this.order = order;
        this.ref = ref;
        this.ean = ean;
        this.name = name;
        this.units = units;
        this.location = location;
        this.serie =  (serie !== undefined ? serie : false);
        this.remaining = remaining;
        this.state = state;
        this.register = register;
        this.updated = {}
        this.images = images;
        this.fields = fields;
        this.stock = stock;
        this.replace = replace;
        this.checked = false;
        this.history = undefined;
        this.showHistory = false;
        this.showInventory = false;
        this.revert = false;
        this.duplicate = duplicate;
        this.line = line;
        this.manual = (manual ? manual : false);
        this.registerDate = (registerDate !== undefined ? format(parseISO(registerDate), 'dd/MM/yyyy HH:mm:ss') : undefined);
        this.inventory = [];
    }
}

export default Product;