import {CFormSelect} from "@coreui/react";
import React from "react";
import ProductStates from "../../utils/ProductStates";
import {StateContext} from "../context/StateProvider";
import OrderStates from "../../utils/OrderStates";


class Filters extends React.Component {

    static contextType = StateContext;

    constructor(props) {
        super(props);
        this.state = {filterState: this.props.filterState};
    }

    handleChange(event) {
        this.props.filter(event.target.value);
    }

    handleChangeMultiple(event) {
        this.props.filter(event.target.selectedOptions);
    }

    render() {

        const {state} = this.context;
        const {productStates, orderStates} = this.props;

        return (
            <div id='filters' className={productStates ? 'card-body' : 'card-body mb-4 pt-3 p-0'}>
                {productStates ?
                    <>
                        <label htmlFor="filter-state" className="form-label"><strong>Filtrar por estado</strong></label>
                        <CFormSelect className='form-control mb-4'
                                     onChange={(e) => this.handleChange(e)}
                                     value={state.filters.length > 0 ? parseInt(state.filters.find(item => item.field === 'state')?.value) + 1 : 3}
                                     id="filter-state"
                                     name="filter-state"
                                     aria-label="Seleccionar estado"
                                     options={ProductStates.states}
                        />
                    </>
                    : ''}
                {/*{orderStates ?*/}
                {/*    <>*/}
                {/*        <label htmlFor="filter-state" className="form-label mb-2"><strong>Filtrar por estado</strong></label>*/}
                {/*        <CFormSelect className='form-control'*/}
                {/*                     onChange={(e) => this.handleChangeMultiple(e)}*/}
                {/*                     value={state.filters.length > 0 ? state.filters.find(item => item.field === 'state')?.value : ['1', '2']}*/}
                {/*                     id="filter-state"*/}
                {/*                     name="filter-state"*/}
                {/*                     multiple={true}*/}
                {/*                     aria-label="Seleccionar estado"*/}
                {/*                     options={OrderStates.getStatesJson()}*/}
                {/*        />*/}
                {/*    </>*/}
                {/*    : ''}*/}

            </div>
        )
    }
}

export default Filters;