import {Navigate} from "react-router-dom";
import React, {Component} from "react";
import AuthService from "../services/auth.service";

class PrivateRoute extends Component {

    constructor(props) {
        super(props);
        this.user = AuthService.getCurrentUser();
    }

    render() {
        if (this.user) {
            return this.props.component;
        } else {
            return (<Navigate to="/login"/>);
        }
    }
}


export default PrivateRoute