import React from "react";
import Modal from "react-bootstrap/Modal";
import {CFormInput, CFormSelect, CModalTitle} from "@coreui/react";
import {
    DataGrid,
    esES,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    GridToolbarContainer
} from "@mui/x-data-grid";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import Box from "../../models/Box";
import AddIcon from "@mui/icons-material/Add";
import {Button} from "@mui/material";
import ButtonReact from "react-bootstrap/Button";


class Weight extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sticker: props.sticker || {
                boxes: []
            },
            boxesModels: {},
            total: 0,
            loading: false,
        };
    }

    handleClose() {
        this.props.handleClose();
    }

    handleConfirmation() {
        this.props.handleConfirm(this.state.sticker);
    }

    handleSave = (id) => {
        this.setState({
            boxesModels: {...this.state.boxesModels, [id]: {mode: GridRowModes.View}},
        });
    };

    handleCancel = (id) => {
        this.setState({
            boxesModels: {
                ...this.state.boxesModels,
                [id]: {mode: GridRowModes.View, ignoreModifications: true},
            },
        });

        const editedRow = this.state.sticker.boxes.find((row) => row.id === id);
        if (editedRow.isNew) {
            this.setState({
                sticker: {
                    ...this.state.sticker,
                    boxes: this.state.sticker.boxes.filter((row) => row.id !== id),
                }
            });
        }
    };

    handleEdit(id) {
        this.setState({
            boxesModels: {
                ...this.state.boxesModels,
                [id]: {mode: GridRowModes.Edit},
            },
        });
    }

    handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    handleRowModesModelChange = (newRowModesModel) => {
        this.setState({
            boxesModels: newRowModesModel,
        });
    };

    async handleDelete(id) {
        const box = this.state.sticker.boxes.find((row) => row.id === id);
        const weight = Box.calculateM3(this.state.total - box.m3);

        this.setState({
            total: weight,
            sticker: {
                ...this.state.sticker,
                boxes: this.state.sticker.boxes.filter((row) => row.id !== id),
                weight: weight
            }
        });
    }

    editToolbar = () => {
        const handleClick = () => {
            const id = (this.state.sticker.boxes.length > 0 ? this.state.sticker.boxes.length + 1 : 1)
            let box = new Box(id, 0, 0, 0);
            box.isNew = true;

            this.setState({
                sticker: {
                    ...this.state.sticker,
                    boxes: [...this.state.sticker.boxes, box],
                }
            })

            this.setState({
                boxesModels: {
                    ...this.state.boxesModels,
                    [id]: {mode: GridRowModes.Edit, fieldToFocus: 'long'}
                }
            })
        };

        return (
            <GridToolbarContainer>
                <Button startIcon={<AddIcon/>} className='bg-primary text-white mb-3' onClick={() => handleClick()}>
                    Añadir caja
                </Button>
            </GridToolbarContainer>
        );
    }

    processRowUpdate = async (newRow, rowOld) => {
        const box = new Box(newRow.id, newRow.high, newRow.wide, newRow.long);
        const updatedRow = {...box, isNew: false};
        const boxes = this.state.sticker.boxes.map((row) => (row.id === newRow.id ? box : row));
        const weight = boxes.reduce((accumulator, currentValue) => accumulator + currentValue.m3, 0);

        this.setState({
            sticker: {
                ...this.state.sticker,
                boxes: boxes,
                weight: weight
            },
            total: weight
        });

        return updatedRow;
    };

    generateColumns() {

        return [
            {
                field: 'id',
                headerName: 'Caja',
                flex: 1,
                hide: true,
            },
            {
                field: 'long',
                headerName: 'Largo',
                type: 'number',
                flex: 1,
                editable: true,
            },
            {
                field: 'wide',
                headerName: 'Ancho',
                type: 'number',
                flex: 1,
                editable: true,
            },
            {
                field: 'high',
                headerName: 'Alto',
                type: 'number',
                flex: 1,
                editable: true,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: '',
                width: 100,
                cellClassName: 'actions',
                getActions: ({id}) => {
                    const isInEditMode =
                        this.state.boxesModels[id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon/>}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={() => this.handleSave(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon/>}
                                label="Cancel"
                                className="textPrimary"
                                onClick={() => this.handleCancel(id)}
                                color="inherit"
                            />,
                        ];
                    }

                    return [
                        <GridActionsCellItem
                            icon={<EditIcon/>}
                            label="Edit"
                            className="textPrimary"
                            onClick={() => this.handleEdit(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon/>}
                            label="Delete"
                            onClick={() => this.handleDelete(id)}
                            color="inherit"
                        />,
                    ];
                },
            },
        ];
    }

    handleCellEditKeyDown = async (params, event) => {

        if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();

            await this.apiRef.current.setEditCellValue({id: params.id, field: params.field, value: event.target.value});
            const columns = this.apiRef.current.getAllColumns();
            const start = columns.findIndex((column) => {
                return column.field === params.field
            })

            for (let i = start + 1; i <= columns.length; i++) {
                if (columns[i]?.editable) {
                    this.apiRef.current.setCellFocus(params.id, columns[i].field);
                    break;
                }

                if (i === columns.length) {
                    this.handleSave(params.id);
                }
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sticker !== prevProps.sticker) {
            this.setState({
                sticker: this.props.sticker,
                total: this.props.sticker.boxes.reduce((accumulator, currentValue) => accumulator + currentValue.m3, 0)
            });
        }
    }

    render() {

        const {sticker, boxesModels, total, loading} = this.state;
        const {show} = this.props;
        const columns = this.generateColumns();

        this.apiRef = React.createRef();
        this.apiRef.current = {};

        return (
            <Modal className='modal-lg' id='weights' show={show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    <CModalTitle>Calcular peso</CModalTitle>
                </Modal.Header>
                <Modal.Body>
                    <DataGrid
                        rows={sticker.boxes}
                        columns={columns}
                        slots={{
                            toolbar: this.editToolbar,
                        }}
                        apiRef={this.apiRef}
                        rowModesModel={boxesModels}
                        onRowEditStop={this.handleRowEditStop}
                        onRowModesModelChange={this.handleRowModesModelChange}
                        processRowUpdate={this.processRowUpdate}
                        onCellKeyDown={this.handleCellEditKeyDown}
                        disableRowSelectionOnClick={true}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        editMode="row"
                        autoHeight={true}
                        loading={loading}
                    />
                    <div className='mt-3'>
                        <strong>Total metros cúbicos:</strong> {total}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonReact variant="primary" onClick={() => this.handleConfirmation()}>
                        Guardar
                    </ButtonReact>
                    <ButtonReact variant="secondary" onClick={() => this.handleClose()}>
                        Cerrar
                    </ButtonReact>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Weight;